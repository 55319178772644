<template>
  <b-modal id="vacation_modal" modal-class="vacation-modal" hide-footer>
    <template #modal-title>
      <p>
        <span class="d-block vacation-modal__title">Reason For Leave</span>
        <span class="vacation-modal__reason">{{ form.reason }}</span>
      </p>
    </template>

    <p class="mb-5">
      <span class="d-block vacation-modal__title">Start Date</span>
      <span class="vacation-modal__date">
        {{ form.start_date }}
      </span>
    </p>

    <p class="mb-5">
      <span class="d-block vacation-modal__title"> End Date </span>
      <span class="vacation-modal__date">
        {{ form.end_date }}
      </span>
    </p>

    <p class="mb-5">
      <span class="d-block vacation-modal__title">Working Days</span>
      <span class="vacation-modal__date">
        {{ form.working_days }}
      </span>
    </p>
  </b-modal>
</template>
  <script>
export default {
  name: "VacationModal",

  props: {
    vacation: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      form: this.vacation
    };
  },
  watch: {
    vacation(newVacation, oldVacation) {
      this.form = newVacation
    }
  },
};
</script>
  <style lang="scss" scoped>
.vacation-modal {
  &__reason {
    color: $dark-blue;
    font-size: 0.875rem;
  }

  &__title {
    color: $faint-blue;
    font-weight: 400;
    font-size: 0.75rem;
    margin-bottom: 0.3rem;
  }

  &__date {
    color: $dark-blue;
    font-size: 0.875rem;
  }
}
</style>
  