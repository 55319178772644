<template>
  <primary-modal
    ref="addVacation"
    modal-id="add_vacation"
    :title="this.isLoading ? 'Please wait' : 'Add Vacation'"
  >
    <div class="add-vacation p-3">
      <b-form>
        <b-form-group
          class="mb-4"
          label="Reason for vacation"
          label-for="reason"
        >
          <b-form-textarea
            id="textarea"
            v-model="form.reason"
            placeholder="Type Here..."
          ></b-form-textarea>
        </b-form-group>
        <b-form-group label="Start Date" label-for="startDate">
          <b-form-input id="startDate" v-model="form.start_date" type="date"></b-form-input>
        </b-form-group>
        <b-form-group label="End Date" label-for="endDate">
          <b-form-input id="endDate" v-model="form.end_date" type="date"></b-form-input>
        </b-form-group>
        <b-form-group label="Working Days" label-for="workingDays">
          <b-form-input id="workingDays" v-model="workingDays" type="text"></b-form-input>
        </b-form-group>
        <b-form-group class="mb-4" label="Client" label-for="client">
          <b-form-select v-model="form.clients" multiple :select-size="1">
            <b-form-select-option v-for="client in clients" :key="client.id" :value="client.id">
              {{ client.name }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-button
          block
          type="submit"
          variant="primary"
          class="mt-5"
          @click="handleSubmit"
        >
          {{ isLoading ? "" : "Submit" }}
          <RingLoader v-if="isLoading" />
        </b-button>
      </b-form>
    </div>
  </primary-modal>
</template>
  <script>
import PrimaryModal from "@/components/PrimaryModal.vue";
import RingLoader from "@/components/loader/RingLoader";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "AddStandUp",
  components: {
    PrimaryModal,
    RingLoader,
  },

  data() {
    return {
      isLoading: false,
      form: {
        reason: "",
        start_date: "",
        end_date: "",
        working_days: null,
        clients: [],
      },
    };
  },

  computed: {
    ...mapGetters(["clients"]),
    workingDays: {
      get() {
        const startDate = new Date(this.form.start_date)
			  const endDate = new Date(this.form.end_date)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.form.working_days = getBusinessDatesCount(startDate,endDate);

        function getBusinessDatesCount(startDate, endDate) {
        let count = 0;
        const curDate = new Date(startDate.getTime());
        while (curDate <= endDate) {
            const dayOfWeek = curDate.getDay();
            if(dayOfWeek !== 0 && dayOfWeek !== 6) count++;
            curDate.setDate(curDate.getDate() + 1);
        }
        return count;
      }
        
    return this.form.working_days          
    },
    set(newValue){
        this.form.working_days = newValue;
      },
    },
  },

  created() {
    this.fetchClients();
    
  },

  methods: {
    ...mapActions(["addVacation", "fetchClients"]),
    handleSubmit(e) {
      console.log(this.form)
      e.preventDefault();
      this.isLoading = true;
      this.$store
        .dispatch("addVacation", this.form)
        .then(() => {
          this.$bvModal.hide("add_vacation");
        })
        .catch(() => {
          //nothing to do here
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },

  
};
</script>
  
  <style lang="scss" scoped>
.add-vacation {
  p {
    color: $grey-tint-2;
    font-size: 0.75rem;
  }
  &__current-date {
    color: #2e2d32;
    font-size: 18px;
  }
}
</style>