<template>
  <div class="dashboard-content py-5">
    <div class="d-flex justify-content-end pb-4 pt-5">
      <div class="m-width-300 mr-2">
        <SearchBox
          v-model="searchVacations"
          bg-color="#D6DDE4"
          :rounded="5"
          type="text"
          placeholder="Search users by name, type..."
        />
      </div>

      <div>
        <!-- <FilterDropdown variant="faint-blue" :filters="filters" @filtered="setFilter" /> -->
        <b-button
          variant="link"
          class="ml-2 btn btn-tertiary text-decoration-none px-3"
          @click="$bvModal.show('add_vacation')"
        >
          Add Vacation
        </b-button>
      </div>
    </div>

    <div class="vacation-table m-rounded-10 mb-5 py-2">
      <b-table responsive striped borderless :fields="fields" :items="filteredVacations">
        <template #cell(action)="data">
          <span @click="moreInfo(data.item.id)">
            <EyeIcon />
          </span>
        </template>
        <template #cell(status)="data">
          <span :class="getVariant(data.item.status)">
            {{ data.item.status }}
          </span>
        </template>
      </b-table>

      <div
        class="m-pagination overflow-auto d-flex p-4 justify-cotnent-between align-items-center"
      >
        <span class="flex-grow-1">
          Showing page {{ currentPage }} of {{ totalRows }}
        </span>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          pills
          align="right"
        >
        </b-pagination>
      </div>
    </div>
    <AddVacationModal />
    <VacationModal id="vacation_modal" :vacation="vacation" />
  </div>
</template>
  
  <script>
import SearchBox from "../../components/SearchBox.vue";
//  import OptionsDropdown from "../../components/OptionsDropdown.vue";
//   import FilterDropdown from "../../components/FilterDropdown.vue";
// import MoreInfoIcon from "../../components/svg/MoreInfoIcon.vue";
import EyeIcon from "../../components/svg/EyeIcon.vue"
import VacationModal from "../../components/vacation/VacationModal.vue";
import AddVacationModal from "../../components/vacation/AddVacationModal.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    SearchBox,
    //  OptionsDropdown,
    //   FilterDropdown,
    // MoreInfoIcon,
    VacationModal,
    AddVacationModal,
    EyeIcon,
  },

  data() {
    return {
      searchVacations: '',
      filterValue: "weekly",
      fields: [
        {
          key: "reason",
          label: "Reason For Leave"
        },
        {
          key: "start_date",
          label: "Start Date"
        },
        {
          key: "end_date",
          label: "Resumption Date"
        },
        {
          key: "working_days",
          label: "Working Days"
        },
        {
          key: "status_readable",
          label: "Status"
        },
        {
          key: "action",
        },
      ],
      borderless: true,
      currentPage: 1,
      perPage: 7,
      statuses: {
        Approved: "text-success",
        Pending: "text-warning",
      },
      filters: [
        {
          name: "Today",
          value: "today",
        },
        {
          name: "Weekly",
          value: "weekly",
        },
        {
          name: "Monthly",
          value: "monthly",
        },
      ],
      items: [
        {
          reason_for_leave: "Srspiciatis",
          start_date: "30/10/21",
          resumption_date: "30/10/21",
          working_days: 4,
          status: "Approved",
          action: "",
        },
        {
          reason_for_leave: "Srspiciatis",
          start_date: "30/10/21",
          resumption_date: "30/10/21",
          working_days: 3,
          status: "Pending",
          action: "",
        },
        {
          reason_for_leave: "Srspiciatis",
          start_date: "30/10/21",
          resumption_date: "30/10/21",
          working_days: 4,
          status: "Approved",
          action: "",
        },
        {
          reason_for_leave: "Srspiciatis",
          start_date: "30/10/21",
          resumption_date: "30/10/21",
          working_days: 3,
          status: "Pending",
          action: "",
        },
        {
          reason_for_leave: "Srspiciatis",
          start_date: "30/10/21",
          resumption_date: "30/10/21",
          working_days: 4,
          status: "Approved",
          action: "",
        },
        {
          reason_for_leave: "Srspiciatis",
          start_date: "30/10/21",
          resumption_date: "30/10/21",
          working_days: 3,
          status: "Pending",
          action: "",
        },
      ],
      options: [
        {
          title: "Details",
          class: ["text-tertiary"],
        },
        {
          title: "Approve Vacation",
          class: ["text-success"],
        },
        {
          title: "Decline Vacation",
          class: ["text-secondary"],
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["vacations", "vacation"]),
    totalRows() {
      return this.vacations?.length
    },
    filteredVacations() {
      return this.vacations.filter((vacation) => {
        return vacation.reason.toLowerCase().match(this.searchVacations.toLowerCase()) ||
               vacation.start_date.match(this.searchVacations) ||
               vacation.end_date.match(this.searchVacations)
      })
    }
  },
  methods: {
    ...mapActions(["fetchVacations", "getVacation"]),
    getVariant(status) {
      return this.statuses[status];
    },
    setFilter(value) {
      console.log(value);
    },
    moreInfo(id) {
      this.$bvModal.show("vacation_modal");
      this.getVacation(id)
      console.log("working");
    },
  },
  created() {
    this.fetchVacations();
  },
};
</script>
  
  <style lang="scss" scoped>
.vacation-table {
  background-color: $white;
}
</style>